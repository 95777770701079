import React from 'react'

import Button from '../../../components/elements/Button'
import Icon from '../../../components/elements/Icon'
import Markdown from '../../../components/elements/Markdown'
import TextField from '../../../components/inputs/TextField'
import TimeInput from '../../../components/inputs/Time'

import { formattedTime, calcTimeString, glueThese } from '../../../Helpers'

import {
  useTracking,
  useUpdateTracking,
} from '../../../context/trainingContext'
import { PreviousResult } from '../MovementDetails'

export default function WorkoutScoringSection({
  children,
}: {
  children: React.ReactNode,
}) {
  const [scoringVisible, setScoringVisible] = React.useState(false)
  return (
    <div className="training--format rpm-submit-score training--format--tracking">
      <div className="workout-section">
        <Button
          kind="text"
          cnames="w-full"
          onClick={() => setScoringVisible(!scoringVisible)}
        >
          <div className="flex--auto-spread">
            <strong>Log Results</strong>
            <div>
              <Icon
                name={scoringVisible ? 'circle-minus' : 'circle-plus'}
                type="far"
                cnames="form-icon"
              />
            </div>
          </div>
        </Button>
        {scoringVisible ? children : null}
      </div>
    </div>
  )
}

export function WorkoutScoringSectionEntry({
  section,
  workoutKeyPath,
  rounds = 1,
  title = undefined,
  isLocked = false,
}: {
  section: Object,
  workoutKeyPath: string[],
  rounds: number,
  title: string,
  isLocked: boolean,
}) {
  const updateTracking = useUpdateTracking()
  const tracking = useTracking(workoutKeyPath)

  const { id, reps, time, weight, timecap, scoreRoundsSeperately } = section

  const finalRounds = scoreRoundsSeperately ? rounds : 1

  const rowClasses =
    finalRounds > 1
      ? 'workout-section--row'
      : 'workout-section--row workout-section--row--full'

  return (
    <div className="workout-section--group">
      {title || section.previous ? (
        <div className="workout-section--title">
          <div className="is-flex">
            <Markdown className="text--bold text--italic">{title}</Markdown>
            {section.levels?.length > 0 && section.levels?.length < 4 ? (
              <sup>{glueThese(section.levels)}</sup>
            ) : null}
          </div>
          <PreviousResult option={section} />
        </div>
      ) : null}
      <div className="workout-section--container">
        <div className={`workout-section--header text--small ${rowClasses}`}>
          {finalRounds > 1 ? (
            <div className="workout-section--cell">Round</div>
          ) : null}
          {reps ? <div className="workout-section--cell">Reps</div> : null}
          {weight ? <div className="workout-section--cell">Weight</div> : null}
          {time ? (
            <div className="workout-section--cell">
              {timecap ? `Time (${timecap} Min Cap)` : `Time`}
            </div>
          ) : null}
        </div>
        {Array.from({ length: finalRounds }).map((_, index) => (
          <div className={rowClasses} key={String(`row-reps-entry--${index}`)}>
            {finalRounds > 1 ? <div>{index + 1}</div> : null}
            {reps ? (
              <div className="workout-section--cell">
                <TextField
                  name="reps"
                  placeholder="0"
                  disabled={isLocked}
                  type="tel"
                  maxlength={4}
                  value={(tracking[id] && tracking[id].reps?.[index]) || ''}
                  changed={event =>
                    updateTracking(
                      [...workoutKeyPath, 'reps'],
                      id,
                      parseInt(event.target.value, 10) || 0,
                      index,
                    )
                  }
                  cnames="form-area--numbers"
                />
              </div>
            ) : null}
            {weight ? (
              <div className="workout-section--cell">
                <TextField
                  name="weight"
                  placeholder="0 pounds"
                  disabled={isLocked}
                  type="tel"
                  maxlength={4}
                  value={(tracking[id] && tracking[id].weight?.[index]) || ''}
                  changed={event =>
                    updateTracking(
                      [...workoutKeyPath, 'weight'],
                      id,
                      parseInt(event.target.value, 10) || 0,
                      index,
                    )
                  }
                  cnames="form-area--numbers"
                />
              </div>
            ) : null}
            {time ? (
              <div className="workout-section--cell">
                <TimeInput
                  name="score-time"
                  disabled={isLocked}
                  value={
                    (tracking[id] &&
                      tracking[id].time?.[index] &&
                      formattedTime(tracking[id].time[index])) ||
                    ''
                  }
                  required
                  changed={value =>
                    updateTracking(
                      [...workoutKeyPath, 'time'],
                      id,
                      calcTimeString(value),
                      index,
                    )
                  }
                  cnames="form-area--numbers"
                />
              </div>
            ) : null}
          </div>
        ))}
      </div>

      {/* {reps ? (
        <div className="workout-section--container">
          <div className="workout-section--header">
            <div>Round</div>
            <div>Reps</div>
          </div>
          {Array.from({ length: rounds }).map((_, index) => (
            <div
              className="workout-section--row"
              key={String(`row-reps-entry--${index}`)}
            >
              <div>{index + 1}</div>
              <TextField
                name="reps"
                placeholder="0"
                type="tel"
                maxlength={4}
                value={(tracking[id] && tracking[id].reps?.[index]) || ''}
                changed={event =>
                  updateTracking(
                    [...workoutKeyPath, 'reps'],
                    id,
                    parseInt(event.target.value, 10) || 0,
                    index,
                  )
                }
                cnames="form-area--numbers"
              />
            </div>
          ))}
        </div>
      ) : null}
      {time ? (
        <div className="workout-section--container">
          <div className="workout-section--header workout-section--row">
            <div className="workout-section--cell">Round</div>
            <div className="workout-section--cell">
              {timecap ? `Time (${timecap} Min Cap)` : `Time`}
            </div>
          </div>
          {Array.from({ length: rounds }).map((_, index) => (
            <div
              className="workout-section--row"
              key={String(`row-time-entry--${index}`)}
            >
              <div className="workout-section--cell">{index + 1}</div>
              <div className="workout-section--cell">
                <TimeInput
                  name="score-time"
                  value={
                    (tracking[id] &&
                      tracking[id].time?.[index] &&
                      formattedTime(tracking[id].time[index])) ||
                    ''
                  }
                  required
                  changed={value =>
                    updateTracking(
                      [...workoutKeyPath, 'time'],
                      id,
                      calcTimeString(value),
                      index,
                    )
                  }
                  cnames="form-area--numbers"
                />
              </div>
            </div>
          ))}
        </div>
      ) : null}
      {weight ? (
        <div className="workout-section--container">
          <div className="workout-section--header">
            <div>Round</div>
            <div>Weight</div>
          </div>
          {Array.from({ length: rounds }).map((_, index) => (
            <div
              className="workout-section--row"
              key={String(`row-weight-entry--${index}`)}
            >
              <div>{index + 1}</div>
              <TextField
                name="weight"
                placeholder="0 pounds"
                type="tel"
                maxlength={4}
                value={(tracking[id] && tracking[id].weight?.[index]) || ''}
                changed={event =>
                  updateTracking(
                    [...workoutKeyPath, 'weight'],
                    id,
                    parseInt(event.target.value, 10) || 0,
                    index,
                  )
                }
                cnames="form-area--numbers"
              />
            </div>
          ))}
        </div>
      ) : null} */}
    </div>
  )
}
